import React from 'react';
import { ContentContainer } from './SharedComponents';

export const ContactUs = () => {
    return <ContentContainer>
        <p>
            Backend developers specializing in financial technology
        </p>
        <p>
            Contact us to find out what we can do for you: <a href={"mailto:sales@veritate.se"}>sales@veritate.se</a>
        </p>
    </ContentContainer>
}
