import { NavLink } from 'react-router-dom';
import React from 'react';
import { Book, List, Megaphone } from './Icons';
import styles from "./Menu.module.scss"
import { joinClasses } from './SharedComponents';

const Links = () => <>
    <Link target={""} text={"About us"}>
        <List />
    </Link>
    <Link target={"projects"} text={"Projects"}>
        <Book />
    </Link>
    <Link target={"testimonials"} text={"Testimonials"}>
        <Megaphone />
    </Link>
</>;

const Link = ({children, text, target}: {children: JSX.Element, text: string, target: string}) => {
    return <NavLink className={({ isActive }) => joinClasses(styles.link, isActive ? styles.active : undefined)}
                        to={target}>
        <div>
            <div />
            <div className={styles.icon}>{children}</div>
            <div />
            <p>{text}</p>
        </div>
    </NavLink>
}

export const MobileMenu = () => <div className={styles.MobileMenu}><Links /></div>
export const TopMenu = () => <div className={styles.TopMenu}><Links /></div>
