import { Card, ContentContainer } from './SharedComponents';
import styles from "./CardView.module.scss"

export const ProjectView = () => {
    return <CardView content={ProjectCopy.map(data => { return {
        ...data,
        header: <CardHeader mainLine={data.header} subLine={data.place} />
    }})} />;
}

export const TestimonialView = () => {
    return <CardView content={TestimonialCopy} />;
}

const CardHeader = ({mainLine, subLine}: {mainLine: string, subLine?: string}) => {
    return <><b>{mainLine}</b>{subLine ? <p className={styles.subLine}>{subLine}</p> : <></> }</>;
}

const TestimonialCopy = [
    {
        header: <CardHeader mainLine={"Arnar Eyjolfsson"} subLine={"Colleague"} />,
        body: "Magnus was an enormous help to me when I joined the company. He showed excellent knowledge of both the domain and the technical aspects of the job. He also showed great initiative and was a key driver in the improvement and development of the product. He has a thirst for knowledge and is very adept at picking up new technologies."
    },
    {
        header: <CardHeader mainLine={"Gustav Bergström"} subLine={"Colleague"} />,
        body: "Magnus is a very skilled developer that I would have liked to worked alongside for an even longer time. While I worked with Magnus I found him a very skilled developer and a great team player. Magnus did always worked for the teams best and always took a big responsibility for the architecture in new development. Magnus was always keen to share his experience to help everyone contribute"
    },
    {
        header: <CardHeader mainLine={"Customer"} />,
        body: "Magnus is a solution oriented developer always focusing on the customers needs. Magnus always presented a possible solution when he had discovered a problem showing us he is good at thinking at his feet and working preemptively. We are very satisfied with Magnus work and the code he created for us."
    },
    {
        header: <CardHeader mainLine={"Anna Nihlen"} subLine={"Product owner"} />,
        body: "Magnus and I worked in the same team at Avanza, he was a developer and I was a product owner. We were a cross functional team which were responsible for helping customers get the best possible overview of their assets at Avanza.\n\nMagnus is always happy and shares energy with a good drive and takes a lot of resposibilty. He compbines a nice and professional demeanor with the teams stakeholders. He can both be personal and informat within a tight development team.\nHe was quickly onboarded in the team, and immediately took resposibility both for solving problems and learing the teams domain.\nI can recommend anyone to hire and work with Magnus."
    },
]

const ProjectCopy = [
    {
        header: "Insights",
        place: "Avanza, Stockholm",
        body: "Was part of the team that created the insights view on Avanza, including creating new widgets, deciding on how to calculate performance and how to handle different company events"
    },
    {
        header: "Position view",
        place: "Avanza, Stockholm",
        body: "Created a new kind of dynamic view in Avanza. Made it possible for different users to save different layouts for the same view and tailor make the view to their needs. This made the migration to the new layout easier for existing customers"
    },
    {
        header: "Backoffice system switch",
        place: "Avanza, Stockholm",
        body: "Was resposible for migrating several of the most important systems in Avanza from a file based API to a Kafka API. As a part of the project the systems had to be tested to ensure the functionality did not change"
    },
    {
        header: "GDPR compliance",
        place: "Stockholm",
        body: "Several projects regarding GDPR compliance. Integrating to customers databases for retrieval and removal of sensitive data. \nDid an integration with Salesforce for fetching customer service data"
    },
    {
        header: "Swedish compliance",
        place: "ComeOn, Stockholm",
        body: "As a part of the payment team rewrote the payment and bonus systems to handle the changes after Sweden opened up the gambling market. \nThe project was mission critical to ensure compliance and the successful completion opened up a lot of new possibilities "
    },
    {
        header: "Payment provider integration",
        place: "ComeOn, Stockholm",
        body: "Integrated several new payment providers for the ComeOn flagship site and its siblings. Integrated a mission critical integration to Swish to ensure customers could deposit money with their cell phones"
    },
    {
        header: "Trades And Positions Service",
        place: "Nasdaq, Stockholm",
        body: "Broke out the trades and position service from a monotlith C++-application to a Java microservice. This project was considered critical by management to make it possible to scale out this service to handle new stock markets performance requirements. As a part of this the system had to be integrated to several different existing systems and replicate existing functionality"
    },
    {
        header: "Credit Service",
        place: "Aphelion, Stockholm",
        body: "Created a new kind of credit system on Aphelion. Broke out the customer specific credit code to different submodules making it possible to sell the solution to new customers with minimal new development. Veritate had to synchronize the development with several of the worlds biggest banks to ensure the credit calculations was correct"
    },
    {
        header: "Trade Capture Service",
        place: "Aphelion, Stockholm",
        body: "Integrating with some of Europe's biggest banks to report executed trades to their backoffice system"
    },
];

const CardView = ({content}: {content: CardData[]}) => {
    return <ContentContainer className={styles.projects}>
        {content.map((copy, index) => <CardContent key={index} {...copy} />)}
    </ContentContainer>
}

const CardContent = (data: CardData) => {
    return <Card header={data.header} className={styles.project}>
        <div className={styles.body} key={data.body}>{data.body.split('\n').map(str => <p key={str}>{str}</p>)}</div>
    </Card>
}

interface CardData {
    header: string | JSX.Element;
    body: string;
}
