import styles from "./SharedScss.module.scss"

export const ContentContainer = ({children, className}: {children: JSX.Element | JSX.Element[], className?: string}) => {
    return <div className={joinClasses(styles.container, className)}>
        {children}
        <div className={styles.filler}/>
    </div>
}

export const Card = (data: CardData) => {
    return <div className={joinClasses(styles.card, data.className)}>
        {data.header && <header className={styles.header}>{data.header}</header>}
        <div className={styles.body}>{data.children}</div>
    </div>;
}

interface CardData {
    header?: string | JSX.Element | JSX.Element[];
    children: JSX.Element | JSX.Element[];
    className?: string;
}

export const joinClasses = (...arr: (string | undefined)[]): string => {
    return arr.filter(element => !!element).join(" ");
}
