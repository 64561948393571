import React from 'react';
import logo from './logo.png';
import styles from './App.module.scss';
import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";
import { ContactUs } from './ContactUs';
import { ProjectView, TestimonialView } from './CardView';
import { MobileMenu, TopMenu } from './Menu';

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route element={<BasePage />}>
                  <Route path="" element={<ContactUs />} />
                  <Route path="projects" element={<ProjectView />} />
                  <Route path="testimonials" element={<TestimonialView />} />
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

const Header = () => {
    return <header className={styles.header}>
        <Link className={styles.LogoContainer} to={""}>
            <Logo />
        </Link>
        <TopMenu />
    </header>
}

const BasePage = () => {
    return <>
        <div className={styles.App}>
            <Header />
            <div className={styles.AppContainer}>
                <Outlet />
            </div>
            <MobileMenu />
        </div>
    </>;
}

const Logo = () => <img src={logo} className={styles.logo} alt="logo" />;


export default App;
